.csm-sidebar .Mui-selected {
    border-right: .4em solid #ff4f1f;
}

.csm-sidebar {
    color: black !important;
}

.csm-sidebar a:-webkit-any-link {
    color: black !important;
}